import AppIcon, {SIZES} from "@/app/components/atomic/AppIcon";

interface Props {
    link: string
    iconClassName: string
    title: string
    className?: string
    backgroundColorClass?: string
    outlineColorClass?: string
    widthClass?: string
    heightClass?: string
    iconSizeClass?: keyof typeof SIZES
    hoverClass?: string
    titleClass?: string
}

export default function IconLinkCard({
    link,
    iconClassName,
    title,
    className,
    titleClass,
    backgroundColorClass = 'bg-white',
    outlineColorClass = 'outline-white',
    widthClass = 'w-[120px]',
    heightClass = 'h-[120px]',
    iconSizeClass = '4xl',
    hoverClass = 'hover:outline hover:outline-4 hover:shadow',
}: Props) {

    return (
        <a
            href={link}
        >
            <div
                className={`text-center gap-y-3 rounded-lg flex flex-col justify-between items-center py-6 transition-all duration-100 ease-in-out
                ${heightClass} ${widthClass} ${outlineColorClass} ${backgroundColorClass} ${hoverClass} ${className}`}
            >
                <AppIcon
                    iconClassName={iconClassName}
                    type="duotone"
                    size={iconSizeClass}
                />

                <div className={titleClass}>{title}</div>
            </div>
        </a>
    )
}
