'use client'
import React, {useState} from "react";

export default function FAQSection() {
    const faqData = [
        {
            question: "Comment choisir la meilleure assurance ?",
            answer: `Pour choisir la meilleure assurance, comparez les offres en fonction du taux d’assurance, des garanties proposées et de la flexibilité des 
            conditions. Nos comparateurs en ligne sont gratuits et vous donnent un aperçu rapide des offres disponibles sur le marché.`
        },
        {
            question: "Comment économiser grâce à un comparateur assurance ?",
            answer: `Un comparateur d’assurance permet de visualiser en quelques clics les offres disponibles et d’évaluer les économies réalisables en 
            fonction de votre profil. En comparant les taux et les garanties, vous pouvez identifier les contrats les plus avantageux, souvent avec des 
            économies jusqu’à 50 % par rapport aux assurances les plus chères.`
        },
        {
            question: "Comment utiliser un comparateur d’assurances ?",
            answer: `Utiliser un comparatif est simple : entrez vos informations, puis comparez les options disponibles. En quelques clics, vous accédez à une 
            liste d’options adaptées à vous et votre budget.`
        },
        {
            question: "Quel est le prix d’une assurance prêt immobilier ?",
            answer: `Le tarif d’une assurance de prêt dépend de plusieurs facteurs, notamment votre âge, état de santé, montant emprunté, et durée du prêt. En 
            général, le coût varie entre 0,10 % et 0,50 % du montant du prêt par an.`
        },
        {
            question: "Quel est le coût d’une mutuelle santé ?",
            answer: `Le coût d’une assurance santé varie en fonction de la couverture choisie, de votre profil et des garanties. En moyenne, une mutuelle santé 
            coûte entre 30 et 140 € par mois.`
        },
        {
            question: "Comment résilier mon ancienne assurance ?",
            answer: `Pour résilier votre ancienne assurance, il suffit d’envoyer une lettre de résiliation à votre assureur en respectant les délais légaux 
            (en fonction de la loi applicable, comme la loi Lemoine). Si vous passez par Réassurez-moi, on s’occupe de cette démarche pour vous gratuitement.`
        },
        {
            question: "Comment changer d’assurance via un comparateur ?",
            answer: `Une fois que vous avez utilisé notre comparateur, vous pouvez souscrire en ligne. Un expert dédié vous guide dans la souscription et se
             charge de résilier votre ancien contrat.`
        }
    ]

    const [activeIndex, setActiveIndex] = useState(null)

    const toggleAnswer = (index: number) => {
        // @ts-ignore
        setActiveIndex(activeIndex === index ? null : index)
    }

    const Schema = faqData.map((item, index) => ({
        "@type": "Question",
        "@id": `https://reassurez-moi.fr#faq-question-${index + 1}`,
        "position": index + 1,
        "name": item.question,
        "answerCount": 1,
        "acceptedAnswer": {
            "@type": "Answer",
            "text": item.answer
        },
        "inLanguage": "fr-FR"
    }))

    return (
        <>
            <div className="px-4 w-full max-w-screen-md">
                <h2 className="text-2xl font-semibold pb-8">On répond à toutes vos questions</h2>

                {faqData.map((data, index) => (
                    <div key={index} className="border-b px-2 py-4 last:border-none">
                        <h3
                            className="text-xl font-semibold pb-2 cursor-pointer"
                            onClick={() => toggleAnswer(index)}
                        >
                            {data.question}
                        </h3>

                        {activeIndex === index && (
                            <p>{data.answer}</p>
                        )}
                    </div>
                ))}
            </div>

            <script
                id="yoastSchema"
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(Schema) ?? ''}}
            />
        </>
    )
}
