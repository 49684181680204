'use client'
import IconLinkCard from "@/app/components/atomic/IconLinkCard";
import {useState} from "react";

export default function SubProductsSection() {

    const [isProductsOpen, setIsProductsOpen] = useState(false)

    const products = [
        {
            name: 'Assurance habitation',
            link: '/guide/assurance-habitation/meilleures',
            faIcon: 'fa-house-chimney',
            alt: 'comparateur assurance habitation',
        },
        {
            name: 'Assurance auto',
            link: '/guide/assurance-auto',
            faIcon: 'fa-car-side',
            alt: 'comparateur assurance auto',
        },
        {
            name: 'Moto',
            link: '/guide/assurance-moto',
            faIcon: 'fa-motorcycle',
            alt: 'assurance moto',
        },
        {
            name: 'Animaux',
            link: '/guide/assurance-chien-chat',
            faIcon: 'fa-paw',
            alt: 'assurance animaux',
        },
        {
            name: 'Prévoyance',
            link: '/guide/prevoyance',
            faIcon: 'fa-umbrella-simple',
            alt: 'assurance prevoyance',
        },
        {
            name: 'Loyer impayé',
            link: '/guide/assurance-loyer-impaye',
            faIcon: 'fa-key',
            alt: 'assurance loyer impaye',
        },
        {
            name: 'Assurance vie',
            link: '/guide/assurance-vie',
            faIcon: 'fa-shield-check',
            alt: 'assurance vie',
        },
        {
            name: 'Protection juridique',
            link: '/guide/protection-juridique',
            faIcon: 'fa-scale-balanced',
            alt: 'assurance protection juridique',
        },
        {
            name: 'Dommages ouvrage',
            link: '/guide/assurance-dommages-ouvrage',
            faIcon: 'fa-house-circle-check',
            alt: 'assurance dommages ouvrage',
        },
        {
            name: 'Obsèques',
            link: '/guide/assurance-obseques',
            faIcon: 'fa-tombstone',
            alt: 'assurance obseques',
        },
        {
            name: 'Décès',
            link: '/guide/assurance-deces',
            faIcon: 'fa-coffin-cross',
            alt: 'assurance deces',
        },
        {
            name: 'Dépendance',
            link: '/guide/assurance-dependance',
            faIcon: 'fa-wheelchair',
            alt: 'assurance dependance',
        },
        {
            name: 'Accidents de la vie',
            link: '/guide/assurance-garantie-accidents-vie',
            faIcon: 'fa-bone-break',
            alt: 'assurance garantie accidents vie',
        },
        {
            name: 'Scolaire',
            link: '/guide/assurance-scolaire',
            faIcon: 'fa-graduation-cap',
            alt: 'assurance scolaire',
        },
        {
            name: 'Assurance PNO',
            link: '/guide/assurance-habitation/comparateur-assurance-pno',
            faIcon: 'fa-key-skeleton',
            alt: 'assurance proprietaire non occupant',
        },
        {
            name: 'Vélo',
            link: '/guide/assurance-loisirs/velo',
            faIcon: 'fa-bicycle',
            alt: 'assurance velo',
        },
        {
            name: 'Rachat crédit',
            link: '/guide/rachat-credit',
            faIcon: 'fa-hand-holding-circle-dollar',
            alt: 'assurance credit',
        },
        {
            name: 'Bateau',
            link: '/guide/assurance-bateau',
            faIcon: 'fa-sailboat',
            alt: 'assurance bateau',
        },
        {
            name: 'Vente immobilière',
            link: '/guide/immobilier',
            faIcon: 'fa-buildings',
            alt: 'vente immobiliere',
        },
        {
            name: 'Plan Épargne Retraite',
            link: '/guide/per',
            faIcon: 'fa-money-bill-trend-up',
            alt: 'plan epargne retraite',
        },
        {
            name: 'Mobile',
            link: '/guide/assurance-mobile',
            faIcon: 'fa-mobile',
            alt: 'assurance mobile',
        },
        {
            name: 'Loisirs',
            link: '/guide/assurance-loisirs',
            faIcon: 'fa-futbol',
            alt: 'assurance loisirs',
        },
        {
            name: 'Crédit à la consommation',
            link: '/guide/credit-consommation',
            faIcon: 'fa-money-check-dollar-pen',
            alt: 'credit à la consommation',
        },
        {
            name: 'Banque',
            link: '/guide/banque',
            faIcon: 'fa-building-columns',
            alt: 'banque',
        },
        {
            name: 'Épargne',
            link: '/guide/epargne',
            faIcon: 'fa-piggy-bank',
            alt: 'epargne',
        },
        {
            name: 'Garantie décennale',
            link: '/guide/pro/garantie-decennale',
            faIcon: 'fa-trowel-bricks',
            alt: 'garantie decennale',
        },
        {
            name: 'Impôts',
            link: '/guide/impots',
            faIcon: 'fa-file-invoice-dollar',
            alt: 'impots',
        },
        {
            name: 'Cashback',
            link: '/guide/cashback',
            faIcon: 'fa-hand-holding-dollar',
            alt: 'cashback',
        },
        {
            name: 'Guide de l\'assurance',
            link: '/guide/assurance',
            faIcon: 'fa-book-open-cover',
            alt: 'guide de l\'assurance',
        },
        {
            name: 'Guide des assurances pro',
            link: '/guide/pro',
            faIcon: 'fa-rectangle-pro',
            alt: 'guide des assurances professionnelles',
        },
    ]

    return (
        <>
            <h2 className={'text-white text-xl md:text-3xl font-semibold'}>
                Découvrez tous nos comparateurs d'assurances
            </h2>

            <div
                className={`flex md:flex-wrap w-full pl-2 py-2 gap-5 overflow-x-auto transition-[max-height]
                             md:max-w-6xl md:justify-center md:pl-0 md:overflow-hidden
                            ${isProductsOpen ? 'lg:max-h-[688px] sm:max-h-[828px]' : 'max-h-[268px]'}`}
            >
                {products.map((product, index) => {
                    return (
                        <IconLinkCard
                            key={`product-${index}`}
                            link={product.link}
                            iconClassName={product.faIcon}
                            title={product.name}
                            outlineColorClass={'outline-blue-300 -outline-offset-1'}
                        />
                    )
                })}
            </div>

            <button
                className={'sm:flex items-center justify-center rounded-full border w-10 h-10 hidden bg-white'}
                onClick={() => setIsProductsOpen(!isProductsOpen)}
            >
                <i className={'fa-solid ' + (isProductsOpen ? 'fa-chevron-up' : 'fa-chevron-down')} />
            </button>
        </>
    )
}
