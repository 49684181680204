'use client'
import {useState} from "react";

export default function PopularPagesSection() {

    const popularPages = [
        {
            title: 'Meilleure mutuelle',
            link: '/guide/mutuelle-sante/meilleure',
        },
        {
            title: 'Mutuelle pas cher',
            link: '/guide/mutuelle-sante/pas-cher',
        },
        {
            title: 'Tarif mutuelle santé',
            link: '/guide/mutuelle-sante/tarif',
        },
        {
            title: 'Mutuelle expatrié',
            link: '/guide/mutuelle-sante/expatries',
        },
        {
            title: 'Renégocier assurance de prêt',
            link: '/guide/assurance-pret-immobilier/renegocier',
        },
        {
            title: 'Rachat de crédit',
            link: '/guide/pret-immobilier/rachat',
        },
        {
            title: 'Rachat de soulte',
            link: '/guide/pret-immobilier/rachat-de-soulte',
        },
        {
            title: 'Taux prêt immobilier',
            link: '/taux-pret-immobilier',
        },
        {
            title: 'Assurance auto',
            link: '/guide/assurance-auto',
        },
        {
            title: 'Assurance habitation',
            link: '/guide/assurance-habitation/resiliation',
        },
    ]

    const [isPopularPagesOpen, setIsPopularPagesOpen] = useState(false)

    return (
        <>
            <h2 className={'text-xl md:text-3xl pb-5'}>Pages populaires</h2>

            <div
                className={'flex lg:flex-wrap lg:justify-center gap-5 overflow-auto lg:overflow-hidden transition-[max-height] ' +
                    (isPopularPagesOpen ? 'lg:max-h-[510px] 2xl:max-h-[320px]' : 'max-h-[150px]')}
            >
                {popularPages?.map((popularPage, index) => {
                    return (
                        <div
                            className={'max-w-1xl'}
                            key={`page-${index}`}
                        >
                            <a
                                href={popularPage.link}
                                className={'w-[220px] h-[150px] rounded-lg border flex flex-col hover:text-blue-500 hover:bg-blue-100 overflow-hidden'}
                            >
                                <div className={'h-5 bg-blue-700 border border-blue-700'}></div>

                                <div className={'text-center px-4 py-7'}>
                                    {popularPage.title}
                                </div>
                            </a>
                        </div>
                    )
                })}
            </div>

            <button
                className={'lg:flex items-center justify-center rounded-full border w-10 h-10 hidden'}
                onClick={() => setIsPopularPagesOpen(!isPopularPagesOpen)}
            >
                <i className={'fa-solid ' + (isPopularPagesOpen ? 'fa-chevron-up' : 'fa-chevron-down')} />
            </button>
        </>
    )
}
