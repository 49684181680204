'use client'
import React, {useEffect, useState} from 'react';

enum TypingSpeed {
    Regular = 180,
}

enum DeletingSpeed {
    Regular = 100,
}

interface Props {
    speed?: number;
    deleteSpeed?: number;
    words?: string[];
    waiting?: number;
}

export default function TypingWriter({speed = TypingSpeed.Regular, deleteSpeed = DeletingSpeed.Regular, words = [], waiting = 500}: Props) {
    const [displayText, setDisplayText] = useState<string[]>([])
    const [typingState, setTypingState] = useState<boolean>(true)
    const [wordIdx, setWordIdx] = useState<number>(0)
    const [currentSpeed, setCurrentSpeed] = useState<number>(speed)
    const [wordToType, setWordToType] = useState<string>(words[wordIdx])

    useEffect(() => {
        setTimeout(type, currentSpeed * 0.8)
    }, [displayText])

    const type = () => {
        if (isTyping()) {
            handleTyping()
        } else if (isDeleting()) {
            handleDeleting()
        } else {
            changeWord()
        }
    };

    const isTyping = () => typingState || wordToType.length > 0;

    const isDeleting = () => !typingState && displayText.length > 1;

    const handleTyping = () => {
        setCurrentSpeed(speed);
        setDisplayText([...displayText, wordToType.charAt(0)]);
        const slicedWord = wordToType.slice(1)
        setWordToType(slicedWord);

        if (wordToType.length === 1) {
            setCurrentSpeed(deleteSpeed + waiting)
            setTypingState(false);
        }
    };

    const handleDeleting = () => {
        setCurrentSpeed(deleteSpeed);
        const newDisplayText = displayText.slice(0, -1)
        setDisplayText(newDisplayText);
        if (displayText.length === 1) {
            setTypingState(true);
        }
    };

    const changeWord = () => {
        if (wordIdx < words.length - 1) {
            setWordToType(words[wordIdx + 1]);
            setWordIdx(wordIdx + 1);
        } else {
            setWordIdx(0);
            setWordToType(words[0]);
        }

        setDisplayText([])
    };

    return (
        <span>
            {displayText.map((char) => char)}
            <span className="cursor">|</span>
        </span>
    );
};
